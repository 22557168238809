import React, { useState, useEffect } from "react"

// Temporary Data
import newsData from "../data/news-details.json"

// Components
import {CustomLinks} from "../components/common/menuUtils"
import BrandLogo from "../images/resiland-logo.svg";

import Layout from "../components/layout"
import BreadCrumbs from "../components/BreadCrumbs/StaticBreadcrumb"
import DirectorProfile from "layout/DiscoverMarylebone/DirectorProfile"
import FormSection from "../components/FormsSection"

import LoaderLogo from "../components/LoaderLogo"

import {GetTeamDetails} from "../queries/common_use_query";

const PropertyDetailsTemplate = (props) => {
    const { loading, error, data } = GetTeamDetails(props.pageContext?.slug);
    const [ details, setGetDetails ] = useState({});

    const myRef = React.createRef();

    const ContactForm = () =>{
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    useEffect(()=>{
        data && data.teams.length > 0 && setGetDetails(data.teams[0])
    },[data])
    
    // Loading logo 
    if (loading) return (
        <LoaderLogo />
    );
    // Loading logo

    //console.log("data", details)

    return (
        <React.Fragment>
            {details && details.Title &&
                <Layout template="property_details" Meta_Title={details.Title} Meta_Description={details.Title}>
                    <BreadCrumbs
                        breadCrumbsData={ 
                            [{
                                name: 'Home',
                                link: '/'
                            },
                            {
                                name: "About Us",
                                link: "/"+CustomLinks.about+"/"
                            },
                            {
                                name: "Our Team",
                                link: "/"+CustomLinks.our_team+"/"
                            },
                            {
                                name: details.Title,
                                // link: '#'
                            }]
                        }
                    />
                    <DirectorProfile details={details} ContactForm={ContactForm} myRef={myRef} />  
   
                    <FormSection Module={{Title:`Contact ${details.Title}`, Form_Type:"Individual_Contact"}} to_email_id={details.Email}  />
                    
                </Layout>
            }
            
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate